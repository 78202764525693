import React, { useState } from "react";
import css from "./LoginLayout.module.css";
import branding from "@/assets/branding.png";
import logo from "@/assets/goCryptoBlack.svg";
import logoWhite from "@/assets/goCryptoWhite.svg";
import LangPicker from "@/src/components/controls/LangPicker/LangPicker";
import { setCookie, useTranslation } from "@/util/helper";
import { connect } from "react-redux";
import MDHead from "@/src/components/layout/MDHead/MDHead";

const LoginLayout = ({ children, languages }) => {
  const { i18n, t } = useTranslation();

  return (
    <div className={css.wrapper}>
      <MDHead />
      <div className={css.staticSection}>
        <div className={css.logoWrapper}>
          <img src={logo} />
        </div>
        <div className={css.imageWrapper}>
          <img src={branding} alt="branding" />
        </div>
      </div>
      <div className={css.loginSection}>
        <div className={css.loginWrapper}>
          <div className={css.langPickerWrapper}>
            <div className={css.flexKeeper} />
            <div className={css.mobileLogoWrapper}>
              <img src={logoWhite} />
            </div>
            <LangPicker
              className={css.langPicker}
              selected={
                languages &&
                languages.find((language) => language.code === i18n.language)
              }
              select={(language) => {
                i18n.changeLanguage(language.code).then(() => {
                  setCookie("language", language.code);
                });
              }}
              options={languages}
              dark
            />
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  languages: state.languages,
});

export default connect(mapStateToProps)(LoginLayout);
